import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { isLink } from "@shared/contentful/contentful.utils";

import styles from "./featured-links-block.module.scss";

interface IProps {
	data: ILinkCflData[];
}

const FeaturedLinksBlock = ({ data }: IProps) => {
	if (!data || data.length === 0) return null;

	return (
		<div className={styles.wrapper}>
			<Swiper spaceBetween={16} slidesPerView="auto">
				{data.map((link) => {
					if (!link || !isLink(link)) return null;
					return (
						<SwiperSlide className={styles.link} key={link.sys?.id}>
							<a href={link.url}>{link.label}</a>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default FeaturedLinksBlock;
